import graphql from 'graphql-tag';

export const EDIT_RANKING_POINTS = graphql`
  mutation EditRankingPoints($input: AdjustmentPointsInput!) {
    editRankingPoints(input: $input) {
      id
      tournamentId
      eventId
      playerId
      pointsType
      pointsAdjustment
      adjustmentDescription
    }
  }
`;
