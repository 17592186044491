// extracted by mini-css-extract-plugin
export var adjustmentEditContainer = "player-points-player-module--adjustment-edit-container--41683";
export var adjustmentText = "player-points-player-module--adjustment-text--df975";
export var bodyBase = "player-points-player-module--body-base--3249b player-points-player-module--site-font--722dc";
export var bodyLarge = "player-points-player-module--body-large--1bca1 player-points-player-module--body-base--3249b player-points-player-module--site-font--722dc";
export var bodyLargeBold = "player-points-player-module--body-large-bold--32ddc player-points-player-module--body-base--3249b player-points-player-module--site-font--722dc";
export var bodyRegular = "player-points-player-module--body-regular--a3e52 player-points-player-module--body-base--3249b player-points-player-module--site-font--722dc";
export var bodyRegularBold = "player-points-player-module--body-regular-bold--a1fc2 player-points-player-module--body-base--3249b player-points-player-module--site-font--722dc";
export var bodySmall = "player-points-player-module--body-small--95694 player-points-player-module--body-base--3249b player-points-player-module--site-font--722dc";
export var bodySmallBold = "player-points-player-module--body-small-bold--f4daf player-points-player-module--body-base--3249b player-points-player-module--site-font--722dc";
export var borderTop = "player-points-player-module--border-top--8684c";
export var breakWordContainer = "player-points-player-module--break-word-container--d892b";
export var buttonIconBase = "player-points-player-module--button-icon-base--5a55c";
export var clickLink = "player-points-player-module--click-link--a2fee";
export var dropdownBase = "player-points-player-module--dropdown-base--b432a";
export var dropdownSelectBase = "player-points-player-module--dropdown-select-base--c5184 player-points-player-module--text-input--e2413";
export var flexCol = "player-points-player-module--flex-col--a2131";
export var formErrorMessage = "player-points-player-module--form-error-message--c213c";
export var h3 = "player-points-player-module--h3--f5122";
export var h4 = "player-points-player-module--h4--5776b";
export var hoverLink = "player-points-player-module--hover-link--836b9";
export var hoverRow = "player-points-player-module--hover-row--7df77";
export var linkButton = "player-points-player-module--link-button--f8467 player-points-player-module--unstyled-button--b8810";
export var membershipContainer = "player-points-player-module--membership-container--1525d player-points-player-module--flex-col--a2131 player-points-player-module--primary-border--2ce17";
export var membershipHeader = "player-points-player-module--membership-header--6542a";
export var membershipHeading = "player-points-player-module--membership-heading--1f73b";
export var membershipLabel = "player-points-player-module--membership-label--9de36";
export var moreFiltersBorderClass = "player-points-player-module--more-filters-border-class--f3972";
export var pageBg = "player-points-player-module--page-bg--dfdb3";
export var playerInfoItem = "player-points-player-module--player-info-item--482ca";
export var playerInfoItemLabel = "player-points-player-module--player-info-item-label--0ac32";
export var playerInfoItemValue = "player-points-player-module--player-info-item-value--7fe6b";
export var pointer = "player-points-player-module--pointer--6c992";
export var primaryBorder = "player-points-player-module--primary-border--2ce17";
export var shadowBoxLight = "player-points-player-module--shadow-box-light--ae761";
export var siteFont = "player-points-player-module--site-font--722dc";
export var slideDownAndFade = "player-points-player-module--slideDownAndFade--a8f05";
export var slideLeftAndFade = "player-points-player-module--slideLeftAndFade--48f23";
export var slideRightAndFade = "player-points-player-module--slideRightAndFade--8f303";
export var slideUpAndFade = "player-points-player-module--slideUpAndFade--afe20";
export var statusDecoration = "player-points-player-module--status-decoration--c7457";
export var textInput = "player-points-player-module--text-input--e2413";
export var textInverted = "player-points-player-module--text-inverted--7b1cb";
export var textMediumDark = "player-points-player-module--text-medium-dark--fd51a";
export var tick = "player-points-player-module--tick--2cbd3";
export var tooltip = "player-points-player-module--tooltip--2bcff";
export var tooltipFont = "player-points-player-module--tooltipFont--2368f";
export var tooltipTitleCloseButton = "player-points-player-module--tooltip-title-close-button--b2bb4 player-points-player-module--unstyled-button--b8810";
export var unstyledButton = "player-points-player-module--unstyled-button--b8810";