// extracted by mini-css-extract-plugin
export var adjustmentEditContainer = "player-points-adjustment-module--adjustment-edit-container--db682";
export var adjustmentText = "player-points-adjustment-module--adjustment-text--24880";
export var bodyBase = "player-points-adjustment-module--body-base--abcce player-points-adjustment-module--site-font--b2148";
export var bodyLarge = "player-points-adjustment-module--body-large--ab877 player-points-adjustment-module--body-base--abcce player-points-adjustment-module--site-font--b2148";
export var bodyLargeBold = "player-points-adjustment-module--body-large-bold--124c4 player-points-adjustment-module--body-base--abcce player-points-adjustment-module--site-font--b2148";
export var bodyRegular = "player-points-adjustment-module--body-regular--fbc6d player-points-adjustment-module--body-base--abcce player-points-adjustment-module--site-font--b2148";
export var bodyRegularBold = "player-points-adjustment-module--body-regular-bold--f5bca player-points-adjustment-module--body-base--abcce player-points-adjustment-module--site-font--b2148";
export var bodySmall = "player-points-adjustment-module--body-small--e5e0d player-points-adjustment-module--body-base--abcce player-points-adjustment-module--site-font--b2148";
export var bodySmallBold = "player-points-adjustment-module--body-small-bold--d4efb player-points-adjustment-module--body-base--abcce player-points-adjustment-module--site-font--b2148";
export var borderTop = "player-points-adjustment-module--border-top--79dda";
export var breakWordContainer = "player-points-adjustment-module--break-word-container--ef56d";
export var buttonIconBase = "player-points-adjustment-module--button-icon-base--93616";
export var clickLink = "player-points-adjustment-module--click-link--8d0cf";
export var dropdownBase = "player-points-adjustment-module--dropdown-base--a248c";
export var dropdownSelectBase = "player-points-adjustment-module--dropdown-select-base--99e4d player-points-adjustment-module--text-input--f9588";
export var flexCol = "player-points-adjustment-module--flex-col--0c6ae";
export var formErrorMessage = "player-points-adjustment-module--form-error-message--11632";
export var h3 = "player-points-adjustment-module--h3--8b2f9";
export var h4 = "player-points-adjustment-module--h4--4b00d";
export var hoverLink = "player-points-adjustment-module--hover-link--dd42b";
export var hoverRow = "player-points-adjustment-module--hover-row--09f7a";
export var membershipContainer = "player-points-adjustment-module--membership-container--05bf0 player-points-adjustment-module--flex-col--0c6ae player-points-adjustment-module--primary-border--4257c";
export var membershipHeader = "player-points-adjustment-module--membership-header--cd423";
export var membershipHeading = "player-points-adjustment-module--membership-heading--7559c";
export var membershipLabel = "player-points-adjustment-module--membership-label--65a99";
export var moreFiltersBorderClass = "player-points-adjustment-module--more-filters-border-class--4c22c";
export var pageBg = "player-points-adjustment-module--page-bg--94b0e";
export var pointer = "player-points-adjustment-module--pointer--a29e5";
export var primaryBorder = "player-points-adjustment-module--primary-border--4257c";
export var shadowBoxLight = "player-points-adjustment-module--shadow-box-light--d2798";
export var siteFont = "player-points-adjustment-module--site-font--b2148";
export var slideDownAndFade = "player-points-adjustment-module--slideDownAndFade--e6904";
export var slideLeftAndFade = "player-points-adjustment-module--slideLeftAndFade--05b6f";
export var slideRightAndFade = "player-points-adjustment-module--slideRightAndFade--54a24";
export var slideUpAndFade = "player-points-adjustment-module--slideUpAndFade--6eece";
export var statusDecoration = "player-points-adjustment-module--status-decoration--6d0b6";
export var textInput = "player-points-adjustment-module--text-input--f9588";
export var textInverted = "player-points-adjustment-module--text-inverted--b9186";
export var textMediumDark = "player-points-adjustment-module--text-medium-dark--c162c";
export var tooltip = "player-points-adjustment-module--tooltip--d3723";
export var tooltipFont = "player-points-adjustment-module--tooltipFont--e3a35";
export var tooltipTitleCloseButton = "player-points-adjustment-module--tooltip-title-close-button--2f581 player-points-adjustment-module--unstyled-button--762d5";
export var unstyledButton = "player-points-adjustment-module--unstyled-button--762d5";